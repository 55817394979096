.badge {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;

    z-index: auto;
    min-width: 20px;
    height: 20px;
    color: #ffffff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #ffffff;
    transition: background 0.2s;
}

.badge-small {
    min-width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    border-radius: 8px;
}

.badge-status-success {
    background-color: #52c41a;
}

.badge-status-processing {
    background-color: #1677ff;
}

.badge-status-error {
    background-color: #ff4d4f;
}

.badge-status-default {
    background-color: rgba(0, 0, 0, 0.85);
}

.badge-status-warning {
    background-color: #faad14;
}

.badge-multiple-characters {
    padding: 0 8px;
}