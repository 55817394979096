.alert {
    box-sizing: border-box;
    margin: 0;
    padding: 8px 12px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    border-radius: 8px;

    margin-bottom: 20px; /*To be removed*/
}

.alert-with-message {
    align-items: flex-start;
    padding-inline: 24px;
    padding-block: 20px;
}

.alert-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}

.alert-info {
    background-color: #e6f4ff;
    border: 1px solid #91caff;
}

.alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
}

.alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
}

.alert-icon {
    margin-inline-end: 8px;
    line-height: 0;
}

.alert-with-message .alert-icon {
    margin-inline-end: 12px;
    font-size: 24px;
    line-height: 0;
}

.alert-success .alert-icon {
    color: #52c41a;
}

.alert-info .alert-icon {
    color: #1677ff;;
}

.alert-warning .alert-icon {
    color: #faad14;;
}

.alert-error .alert-icon {
    color: #ff4d4f;
}

.alert-icon svg {
    display: inline-block;
}

.alert .alert-content {
    flex: 1;
    min-width: 0;
}

.alert-with-message .alert-title {
    display: block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
}

.alert-with-message .alert-message {
    display: block;
}

.alert .alert-close-icon {
    margin-inline-start: 8px;
    padding: 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 12px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.icon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alert-close-icon .close {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.2s;
}