.breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;

    margin-bottom: 20px /*To be removed*/
}

.breadcrumb ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.2s;
    padding: 0 4px;
    border-radius: 4px;
    height: 22px;
    display: inline-block;
    margin-inline: -4px;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.breadcrumb .breadcrumb-separator {
    margin-inline: 8px;
    color: rgba(0, 0, 0, 0.45);
}

.breadcrumb li:last-child {
    color: rgba(0, 0, 0, 0.88);
}