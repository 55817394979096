.main {
    /* float: left; */
    padding: 20px 40px;
    background-color: transparent;
    /* width: calc(100% - 280px);
    height: calc(100% - 40px); */
    margin-left: 250px;
  }

  .main section {
    background-color: #FFF;
    box-shadow: 0 1px 2px #ccc;
    margin-bottom: 25px;
    padding: 10px 0;
  }


  section .title {
    padding: 0 20px;
    color: rgba(0,0,0,.87);
    font-weight: 600;
    font-size: 17px;
    font-family: inherit;
  }

  section .section-body {
    padding: 30px 20px 15px;
  }

