:root {
  --primary-color: #22c55e;
  --primary-color-active: #0e4f26;
  --primary-color-hover:#76db9b;
}

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}