.btn {
    position: relative;
    font-weight: 600;
    font-size: 12px;
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    vertical-align: middle;
    border: none;
    outline: none;
    user-select: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid transparent;

    margin-right: 12px; /*To be removed*/
}

.btn-primary {
    color: #FFF;
    background-color: var(--primary-color);
}

.btn-primary:not(.btn-disabled):active {
    color: #FFF;
    background-color: var(--primary-color-active);
}

.btn-primary:not(.btn-disabled):hover {
    color: #FFF;
    background-color: var(--primary-color-hover);
}

.btn-default {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: #FFF;
}

.btn-default:not(.btn-disabled):active,
.btn-dashed:not(.btn-disabled):active{
    color: var(--primary-color-active);
    border-color: var(--primary-color-active);
}

.btn-default:not(.btn-disabled):hover,
.btn-dashed:not(.btn-disabled):hover {
    color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
}

.btn-dashed {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: #FFF;
    border-style: dashed
}

.btn-small {
    height: 24px;
    padding: 0 7px;
    border-radius: 4px;
}

.btn-medium {
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}

.btn-large {
    font-size: 16px;
    height: 40px;
    padding: 6.42px 15px;
    border-radius: 8px;
}

.btn-disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0,0,0,.25);
    background-color: rgba(0,0,0,.04);
}