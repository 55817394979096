nav {
    width: 250px;
    background-color: #393939;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    z-index: 1;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
}
  
  nav li {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
  
  nav li:has(.active) {
    background-color: #FFFFFF;
  }

  nav li a.active {
    color: #393939;
    font-weight: 600;
  }

  nav li:hover {
    background-color: #FFFFFF;
  }

  nav li:hover a {
    color: #393939;
    font-weight: 600;
  }

  nav a {
    text-decoration: none;
    color: #FFFFFF;
    padding: 10px 30px;
    width: calc(100% - 60px);
    display: inline-block;
  }
  
  nav a:hover {
    color: #393939;
  }

  nav li:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 60px);
    padding: 15px 30px;
  }

  nav li:first-child span {
    width: 40px;
    height: 35px;
    background-color: gray;
    border-radius: 3px;
    margin-right: 15px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 35px;
    letter-spacing: 0.5px;
  }

  nav li:first-child a {
    font-weight: 600;
    padding: 0;
  }
 

  :focus-visible {
    outline: none;
  }