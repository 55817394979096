.txtbox {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    margin-bottom: 20px /*To be removed*/
}

.txtbox:not(.txtbox-disabled):focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border-inline-end-width: 1px;
    outline: 0;
}

.txtbox:not(.txtbox-disabled):hover {
    border-color: var(--primary-color);
    border-inline-end-width: 1px;
}

.txtbox-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 1;
}

.txtbox::-moz-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.txtbox::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.txtbox::placeholder {
    color: rgba(0, 0, 0, 0.25);
}

