.progress {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    display: inline-block;
}

.progress-line {
    position: relative;
    width: 100%;
    font-size: 14px;
    margin-inline-end: 8px;
    margin-bottom: 8px;
}

.progress .progress-outer {
    display: inline-block;
}

.progress .progress-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 100px;
}

.progress .progress-bg {
    position: relative;
    background-color: #1677ff;
    border-radius: 100px;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.progress.progress-status-success .progress-bg {
    background-color: #52c41a;
}

.progress.progress-status-exception .progress-bg {
    background-color: #ff4d4f;
}

.progress-circle-inner {
    font-size:18px;
    position: relative;
    line-height: 1;
    background-color: transparent;
    display: flex;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 100px;

    margin-right: 30px; /*To be removed*/
}

.progress-circle-trail {
    stroke-linecap: round;
    stroke-width: 6px;
    stroke-dasharray:295.31px 295.31; 
    stroke-dashoffset: 0;
    transform:rotate(-90deg);
    transform-origin:0 0;
    transition:stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s, stroke-width .06s ease .3s, opacity .3s ease 0s;
    fill-opacity:0;
    stroke: rgba(0, 0, 0, 0.06);
}

.progress-circle-path {
    stroke-linecap: round;
    stroke-width: 6px;
    opacity: 1;
    stroke-dasharray: 295.31px, 295.31;  
    /* stroke-dashoffset: 200;  */
    transform: rotate(-90deg);
    transform-origin: 0px 0px; 
    transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s, opacity ease 0s; 
    fill-opacity: 0;
    stroke: #1677ff;
}

.progress.progress-status-success .progress-circle-path {
    stroke: #52c41a;
}

.progress.progress-status-exception .progress-circle-path {
    stroke: #ff4d4f;
}

.progress-circle-path-2{
    stroke-linecap: round;
    stroke-width: 6px;
    opacity: 0;
    stroke: rgb(82, 196, 26);
    stroke-dasharray: 295.31px, 295.31;
    stroke-dashoffset: 295.31;
    transform: rotate(-90deg);
    transform-origin: 0px 0px;
    transition: stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s, opacity ease 0s; 
    fill-opacity: 0;
}

    

.progress-circle .progress-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.88);
    word-break: normal;
    line-height: 1;
    white-space: normal;
}