.radio-wrapper {
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: baseline;
    color: rgba(0, 0, 0, 0.88);
    line-height: 22px;

    margin-right: 12px; /*To be removed*/
}

.radio {
    position: relative;
    display: inline-block;
    outline: none;
    cursor: pointer;
    align-self: center;
}

.radio .radio-input {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
}

.radio .radio-inner {
    box-sizing: border-box;
    position: relative;
    inset-block-start: 0;
    inset-inline-start: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all 0.2s;
}

.radio-small .radio .radio-inner {
    width: 16px; 
    height: 16px;
}

.radio-large .radio .radio-inner {
    width: 24px; 
    height: 24px;
}

.radio.radio-checked .radio-inner {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.radio .radio-inner:after {
    box-sizing: border-box;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-block-start: -10px;
    margin-inline-start: -10px;
    background-color: #fff;
    border-block-start: 0;
    border-inline-start: 0;
    border-radius: 20px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: "";
}

.radio-small .radio .radio-inner:after{
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-block-start: -8px;
    margin-inline-start: -8px;
}

.radio-large .radio .radio-inner:after{
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-block-start: -12px;
    margin-inline-start: -12px;
}

.radio.radio-checked .radio-inner:after{
    transform: scale(0.375);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.radio-wrapper .radio-checked:after{
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    visibility: hidden;
    animation-name: css-12jzuas-antRadioEffect;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-fill-mode: both;
    content: "";
}

.radio-wrapper span.radio+* {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
}

.radio-wrapper-disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
}

.radio-wrapper .radio-disabled {
    cursor: not-allowed;
}

.radio-wrapper .radio-disabled .radio-input {
    cursor: not-allowed;
}

.radio-wrapper .radio-disabled .radio-inner {
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    cursor: not-allowed;
}

.radio-wrapper .radio-disabled .radio-inner:after {
    background-color: rgba(0, 0, 0, 0.25);
}

.radio-wrapper .radio-disabled.radio-checked .radio-inner::after {
    transform: scale(0.5);
}