.txt-area-wrapper {
    position: relative;
    margin-bottom: 24px;
    padding: 0;
    display: inline-flex;
    width: 100%;
}

.txt-area {
    width: 100%;
    height: 100%;
    min-height: 80px;
    font-family: inherit;
    border: none;
    outline: none;
    vertical-align: bottom;
    resize: vertical;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

.txt-area:not(.txt-area-disabled):hover,
.txt-area:not(.txt-area-disabled):focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}

.txt-area-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 1;
}

.txt-area-wrapper .txt-area-count {
    position: absolute;
    bottom: 5px;
    right: 8px;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    pointer-events: none;
}

.txt-area-wrapper .txt-area-count.danger {
    color: red;
}

.txt-area::-moz-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.txt-area::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.txt-area::placeholder {
    color: rgba(0, 0, 0, 0.25);
}