.avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    margin-right: 20px; /*To be removed*/
}



.avatar-small {
    width: 32px;
    height: 32px;
    line-height: 22px;
    border-radius: 50%;
}

.avatar-large {
    width: 64px;
    height: 64px;
    line-height: 38px;
    border-radius: 50%;
}

.avatar-xlarge {
    width: 100px;
    height: 100px;
    /*line-height: 38px;*/
    border-radius: 50%;
}

.avatar-square {
    border-radius: 6px;
}

.avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}