.checkbox-wrapper {
    font-family: inherit;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: baseline;
    color: rgba(0, 0, 0, 0.88);
    line-height: 22px;

    margin-right: 12px; /*To be removed*/
}

.checkbox {
    position: relative;
    white-space: nowrap;
    cursor: pointer;
    align-self: center; 
}

.checkbox .checkbox-input {
    position: absolute;
    inset: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    margin: 0;
}

.checkbox .checkbox-inner {
    box-sizing: border-box;
    position: relative;
    top: 0;
    inset-inline-start: 0;
    display: block;
    width: 20px;
    height: 20px;
    direction: ltr;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    border-collapse: separate;
    transition: all 0.3s;
}

.checkbox-small .checkbox .checkbox-inner{
    width: 16px;
    height: 16px;
}

.checkbox-large .checkbox .checkbox-inner{
    width: 24px;
    height: 24px;
}

.checkbox .checkbox-inner:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    inset-inline-start: 21.5%;
    display: table;
    width: 7.1425px;
    height: 11.4275px;
    border: 2px solid #fff;
    border-top: 0;
    border-inline-start: 0;
    transform: rotate(45deg) scale(0) translate(-50%,-50%);
    opacity: 0;
    content: "";
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
}

.checkbox-small .checkbox .checkbox-inner:after{
    width: 5.7142857142857135px;
    height: 9.142857142857142px;
}

.checkbox-large .checkbox .checkbox-inner:after{
    width: 8.571px;
    height: 13.713px;
}

.checkbox-wrapper-checked.checkbox-large .checkbox .checkbox-inner:after{
    top: 45%;
}

.checkbox+span {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
}

.checkbox-wrapper:not(.checkbox-wrapper-disabled):hover .checkbox-inner {
    border-color: var(--primary-color);
}

.checkbox:not(.checkbox-disabled):hover .checkbox-inner {
    border-color: var(--primary-color);
}

.checkbox-checked .checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.checkbox-checked .checkbox-inner:after {
    opacity: 1;
    transform: rotate(45deg) scale(1) translate(-50%,-50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.checkbox-indeterminate .checkbox-inner:after {
    top: 50%;
    inset-inline-start: 50%;
    width: 12px;
    height: 12px;
    background-color: var(--primary-color);
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: "";
    border-radius: 2px;
}

.checkbox-small .checkbox-indeterminate .checkbox-inner:after {
    width: 9px;
    height: 9px;
}

.checkbox-large .checkbox-indeterminate .checkbox-inner:after {
    width: 15px;
    height: 15px;
}

.checkbox-wrapper-disabled {
    cursor: not-allowed;
}

.checkbox-disabled,
.checkbox-disabled .checkbox-input {
    cursor: not-allowed;
    pointer-events: none;
}

.checkbox-disabled .checkbox-inner {
    background: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
}

.checkbox-disabled .checkbox-inner:after {
    border-color: rgba(0, 0, 0, 0.25);
}

.checkbox-disabled.checkbox-indeterminate .checkbox-inner:after {
    background: rgba(0, 0, 0, 0.25);
}
